<style lang="scss">
.configs {
  .content-in-right {
    margin-left: 250px;
  }
  .menu-list-alignment {
    width: 262px;
  }
}
</style>

<template>
  <div class="configs">
    <v-container fluid>
      <v-navigation-drawer
        absolute
        v-model="drawer"
        :clipped="$vuetify.breakpoint.mdAndUpp"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ __("Configurações", "settings") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ __("Sistema", "settings") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list dense class="menu-list-alignment">
          <v-list-group
            prepend-icon="mdi-folder"
            group="configs/attachments"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title>{{
                __("Anexos", "schedulings")
              }}</v-list-item-title>
            </template>

            <v-list-item
              :to="{ name: 'attachment_upload_configs' }"
              color="primary"
              link
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ __("Upload", "settings") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'attachment_categories_configs' }"
              color="primary"
              link
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ __("Gestão de categorias", "settings") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'attachment_default_categories_configs' }"
              color="primary"
              link
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ __("Categorias padrões", "settings") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              no-action
              sub-group
              group="configs/attachments/insurer"
            >
              <template v-slot:activator>
                <v-list-item-content class="pl-2">
                  <v-list-item-title>{{
                    __("Companhia", "settings")
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :to="{ name: 'attachment_insurer_restrictions_configs' }"
                color="primary"
                link
              >
                <v-list-item-title>
                  {{ __("Restrição de envio", "settings") }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{
                  name: 'attachment_insurer_available_categories_configs',
                }"
                color="primary"
                link
              >
                <v-list-item-title>
                  {{ __("Categorias disponíveis", "settings") }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group no-action sub-group group="configs/attachments/unity">
              <template v-slot:activator>
                <v-list-item-content class="pl-2">
                  <v-list-item-title>{{
                    __("Unidade", "settings")
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :to="{ name: 'attachment_unity_available_categories_configs' }"
                color="primary"
                link
              >
                <v-list-item-title>
                  {{ __("Categorias disponíveis", "settings") }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-group>

          <ScopeProvider scope="configs.eps.read">
            <v-list-group
              prepend-icon="mdi-server-network"
              no-action
              subgroup
              group="/configs/eps"
            >
              <template v-slot:activator>
                <v-list-item-title> EPS </v-list-item-title>
              </template>

              <v-list-item
                :to="{ name: 'eps_attachments_configs' }"
                color="primary"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("Adicionar anexo", "settings") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="{ name: 'eps_attachment_categories' }"
                color="primary"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("Categorias", "settings") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="{ name: 'eps_blocked_attachment_types' }"
                color="primary"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("Bloqueio - tipos de anexo", "settings") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </ScopeProvider>

          <ScopeProvider scope="configs.email.read">
            <v-list-group
              prepend-icon="mdi-email"
              no-action
              subgroup
              group="/configs/email"
            >
              <template v-slot:activator>
                <v-list-item-title> Email </v-list-item-title>
              </template>

              <v-list-item
                :to="{ name: 'emails_unities' }"
                color="primary"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("Unidades - Reply to", "settings") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </ScopeProvider>

          <v-list-group
            prepend-icon="mdi-briefcase"
            no-action
            group="/configs/leads/"
          >
            <template v-slot:activator>
              <v-list-item-title> Leads </v-list-item-title>
            </template>

            <v-list-item
              v-if="verifyScope('configs.leads.statuses.edit')"
              color="primary"
              link
              :to="{
                name: 'configs.leads.statuses',
              }"
            >
              <v-list-item-title>
                {{ __("Status", "settings") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              color="primary"
              link
              :to="{
                name: 'leads_completed_statuses',
              }"
            >
              <v-list-item-title>
                {{ __("Status concluído", "settings") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              color="primary"
              link
              :to="{
                name: 'leads_active_statuses_unities',
              }"
            >
              <v-list-item-title>
                {{ __("Autonomia - unidades", "settings") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              color="primary"
              link
              :to="{
                name: 'leads_statuses_automation',
              }"
            >
              <v-list-item-title>
                {{ __("Status - automatização", "settings") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              color="primary"
              link
              :to="{
                name: 'leads_block_statuses_automation',
              }"
            >
              <v-list-item-title>
                {{ __("Bloqueio - automatização", "settings") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <ScopeProvider scope="configs.distribution.read">
            <v-list-group
              prepend-icon="mdi-arrow-decision-auto"
              no-action
              subgroup
              group="/configs/leads_distribution"
            >
              <template v-slot:activator>
                <v-list-item-title>
                  {{ __("Distribuição de Leads", "settings") }}
                </v-list-item-title>
              </template>

              <v-list-item
                :to="{ name: 'leads_distribution_activate_deactivate' }"
                color="primary"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      __("Ativar", "settings") +
                      " / " +
                      __("Desativar", "settings")
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                :to="{ name: 'unities_availability_score' }"
                color="primary"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("Score Unidades", "settings") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </ScopeProvider>

          <ScopeProvider scope="configs.products.read">
            <v-list-group
              prepend-icon="mdi-devices"
              no-action
              subgroup
              group="/configs/equipaments"
            >
              <template v-slot:activator>
                <v-list-item-title>
                  {{ __("Equipamentos", "settings") }}
                </v-list-item-title>
              </template>

              <v-list-item
                :to="{ name: 'config_equipament_types' }"
                color="primary"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("Tipos de equipamento", "settings") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </ScopeProvider>

          <v-list-item
            v-if="verifyScope('configs.insurers_portal.theme.edit')"
            :to="{ name: 'configs.insurers_portal' }"
            color="primary"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ __("Portal das companhias", "settings") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="{ name: 'form-options-categories' }"
            color="primary"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-form-dropdown</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ __("Formulários", "settings") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div :class="{ 'content-in-right': drawer }">
        <div v-if="!drawer">
          <v-btn @click="drawer = !drawer" icon>
            <v-icon>mdi-arrow-expand-right</v-icon>
          </v-btn>
        </div>
        <router-view></router-view>
      </div>
    </v-container>
  </div>
</template>

<script>
import ScopeProvider from "@/components/ScopeProvider";
import i18n from "@/mixins/i18n";
import ScopesMixin from "../mixins/ScopesMixin.vue";

export default {
  components: {
    ScopeProvider,
  },
  mixins: [i18n, ScopesMixin],
  data() {
    return {
      drawer: null,
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
    };
  },
};
</script>
